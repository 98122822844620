<template>
  <div v-if="backgroundFile" id="fullscreen-background" :key="'bg_' + $route.path">
    <Lotti v-if="lottiBackground" class="lottibg" :options="defaultOptions" v-on:animCreated="handleAnimation"/>
    <img v-else :src="backgroundFile" alt="background" />
  </div>
</template>

<script>
/**
 * This component is used to display the background of the application, preferred the Lotti, else we fallback to background.png.
 * First we check if there is a Lottie available specific for this activityID, if not we check if there is a Lottie available for the preferredNo (page).
 * For skill specific backgrounds we check if there is a Lottie available for the preferredSkill, if not we fallback to the default background (if we have).
 */
import { has } from 'lodash'
import Lotti from '@/components/Lotti'

export default {
  name: 'LottiBackground',

  components: {
    Lotti
  },

  props: {
    preferredActivityID: String,
    preferredNo: {
      type: Number,
      default: 1
    },
    preferredSkill: String
  },

  computed: {
    defaultOptions () {
      return {
        loop: true,
        autoplay: true,
        animationData: this.backgroundFile
      }
    },

    lottiBackground () {
      if (has(this.backgroundFile, 'v')) {
        return true
      }

      return this.backgroundFile && this.backgroundFile.slice(-4) !== '.png'
    },

    backgroundFile () {
      switch (this.preferredNo) {
        case 1:
          try {
            return require(`@/build/${this.$canonicalUser}/img/background.json`)
          } catch (error) {
            return this.getDefaultBackground()
          }
        case 2:
          try {
            return require(`@/build/${this.$canonicalUser}/img/background-${this.preferredActivityID}.json`)
          } catch (error) {
            try {
              return require(`@/build/${this.$canonicalUser}/img/background2.json`)
            } catch {
              return this.getDefaultBackground()
            }
          }
        case 3:
          try {
            return require(`@/build/${this.$canonicalUser}/img/background-${this.preferredActivityID}-${this.preferredSkill}.json`)
          } catch {
            try {
              return require(`@/build/${this.$canonicalUser}/img/background-${this.preferredSkill}.json`)
            } catch {
              try {
                return require(`@/build/${this.$canonicalUser}/img/background3.json`)
              } catch {
                return this.getDefaultBackground()
              }
            }
          }
        default:
          return this.getDefaultBackground()
      }
    }
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },

    getDefaultBackground () {
      try {
        return require(`@/build/${this.$canonicalUser}/img/background.json`)
      } catch (error) {
        try {
          return require(`@/build/${this.$canonicalUser}/img/child-background.png`)
        } catch (error) {
          return false
        }
      }
    }
  }
}
</script>
