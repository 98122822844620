<template>
  <div class="w-100">
    <div class="card card-hover mb-2 mb-md-3 border-0 bg-light">
      <div class="card-body position-relative">
        <b-media class="align-items-center">
          <template #aside>
            <font-awesome-icon class="text-dark" :icon="['fad', 'user-circle']" size="3x"/>
          </template>
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ name }}</h5>
<!--            <router-link-->
<!--              :to="{ path: personUrl }"-->
<!--              @click.native="onClickLink">-->
<!--              <div class="btn btn-primary"><font-awesome-icon :icon="['fas', 'user']" class="mr-2"/>Bekijk</div>-->
<!--            </router-link>-->
          </div>
        </b-media>

        <hr>

        <div class="row">
          <div class="col-5 col-sm-4 col-lg-3 text-right">{{ $t("common.date_of_birth") }}:</div>
          <div class="col">{{ formatDate(dateOfBirth, 'DD-MM-YYYY') }}</div>
        </div>

        <div class="row">
          <div class="col-5 col-sm-4 col-lg-3 text-right">{{ $t("context.barcode") }}:</div>
          <div class="col">{{ barcode }}</div>
        </div>

        <template v-if="activityGroups.length > 0">
          <hr>

          <h6>{{ $t("context.level_slash_groups") }}</h6>

          <div class="mb-0">
            <div :key="activityGroup.ActivityGroupID" v-for="activityGroup in activityGroups" class="card card-body mb-2">
              <strong>{{ activityGroup.SkillCategoryID }}</strong> {{ activityGroup.Name }}
              <div v-if="activityGroupSubscriptionAgenda(activityGroup)">
                <router-link
                             :to="activityGroupSubscriptionAgendaUrl(activityGroup)"
                             @click.native="onClickLink">
                  <div class="btn btn-outline-dark mt-2 mr-2"><font-awesome-icon :icon="['fas', 'calendar-day']" class="mr-2"/>{{ $t("actions.view") }}</div>
                </router-link>
                <router-link
                  :to="activityGroupSubscriptionAgendaPersonUrl(activityGroup)"
                  @click.native="onClickLink">
                  <div class="btn btn-outline-dark mt-2"><font-awesome-icon :icon="['fas', 'user-clock']" class="mr-2"/>{{ $t("context.manage_student_in_group") }}</div>
                </router-link>
              </div>
              <div v-else class="font-italic">{{ $t("context.no_future_or_historical_lessons") }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment'

export default {
  name: 'Student',
  props: {
    student: Object
  },
  computed: {
    name () {
      return this.student.Name
    },
    dateOfBirth () {
      return this.student.DateOfBirth
    },
    barcode () {
      return this.student.Barcode
    },
    address () {
      return `${this.student.Address2} ${this.student.Address3}`
    },
    email () {
      return this.student.Email
    },
    parentName () {
      return this.student.Customer.Name
    },
    phone () {
      return this.student.Phone
    },
    activityGroups () {
      return this.student.ActivityGroup.map(group => ({
        ...group,
        subscriptionAgenda: this.activityGroupSubscriptionAgenda(group)
      }))
    },
    personSubscriptionAgendas () {
      return this.student.Person_SubscriptionAgenda
    },
    personUrl () {
      return '/persons/' + this.student.PersonID
    }
  },
  methods: {
    onClickLink () {
      this.$emit('clicked')
    },
    formatDate (date, format) {
      return moment(date).format((format))
    },
    activityGroupSubscriptionAgenda (activityGroup) {
      if (Object.keys(activityGroup.SubscriptionAgenda).length <= 0) {
        return null
      }
      const relevantSubscriptions = this.personSubscriptionAgendas.filter(subscription => {
        return (
          subscription['Person_SubscriptionAgenda.CancelYN'] === false &&
          subscription['SubscriptionAgenda.CancelYN'] === false &&
          subscription['Person_SubscriptionAgenda.SubscriptionAgendaID'] === activityGroup.SubscriptionAgenda[0]['SubscriptionAgendaID']
        )
      })

      if (relevantSubscriptions.length > 0) {
        const futureSubscriptions = relevantSubscriptions.filter(subscription => {
          const startDate = new Date(subscription['SubscriptionAgenda.StartDate'])
          return startDate > Date.now()
        })

        if (futureSubscriptions.length > 0) {
          return futureSubscriptions.sort((a, b) => {
            const startA = new Date(a['SubscriptionAgenda.StartDate'])
            const startB = new Date(b['SubscriptionAgenda.StartDate'])
            return startB - startA
          })[0]
        } else {
          return relevantSubscriptions.sort((a, b) => {
            const startA = new Date(a['SubscriptionAgenda.StartDate'])
            const startB = new Date(b['SubscriptionAgenda.StartDate'])
            return startB - startA
          })[0]
        }
      }

      return null
    },

    activityGroupSubscriptionAgendaUrl (activityGroup) {
      const subscriptionAgenda = activityGroup.subscriptionAgenda
      return {
        path: '/subscription-agenda/' + subscriptionAgenda['Person_SubscriptionAgenda.SubscriptionAgendaID']
      }
    },
    activityGroupSubscriptionAgendaPersonUrl (activityGroup) {
      const subscriptionAgenda = activityGroup.subscriptionAgenda
      return {
        path: '/subscription-agenda/' + subscriptionAgenda['Person_SubscriptionAgenda.SubscriptionAgendaID'] + '/persons/' + this.student.PersonID
      }
    }
  }
}
</script>

<style scoped>

</style>
