<template>
  <b-navbar type="light" :toggleable="menu.length > 0 ? 'lg' : undefined" variant="light" class="shadow justify-content-start">
    <b-navbar-toggle :target="_id('nav-collapse')" class="mr-3" v-if="menu.length > 0"></b-navbar-toggle>

    <b-navbar-brand :to="{name: 'home'}" class="d-flex align-items-center">
      <img :src="logoUrl" class="mr-3 navbar-brand--logo" style="height: 50px; max-width: 15rem;" v-if="logoUrl" alt="Logo"/>
      <span class="d-none d-md-inline navbar-brand--payoff">Dataduiker LVS</span>
    </b-navbar-brand>

    <b-collapse :id="_id('nav-collapse')" is-nav v-if="menu.length > 0">
      <b-navbar-nav id="lvs-nav-menu">
        <b-nav-item :disabled="!link.Url" :href="link.Url || '#'" :key="link.RecNO" v-for="link in menu">{{ link.name }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <b-navbar-nav class="ml-auto flex-row no-collapse profile-toggle justify-content-end align-items-center">
      <b-nav-item-dropdown class="mr-2" right v-if="loggedIn && isAdmin">
        <template slot="button-content">
          <span v-if="activeView">{{ activeView.name }}</span>
          <span v-else-if="activeEmployee">{{ activeEmployee.Name }}</span>
          <em v-else>Kies weergave</em>
        </template>

        <b-dropdown-header :id="_id('dropdown-header-label-views')" class="text-uppercase">
          Overzichten
        </b-dropdown-header>

        <b-dropdown-item
          @click.prevent="switchView(view)"
          :active="activeView && view.id === activeView.id"
          :aria-describedby="_id('dropdown-header-label-views')"
          :key="view.id"
          v-for="view in views"
        >
          {{ view.name }}
        </b-dropdown-item>

        <b-dropdown-header :id="_id('dropdown-header-label-teachers')" class="text-uppercase">
          {{ $t("entities.teacher", { count: 2 }) }}
        </b-dropdown-header>

        <b-dropdown-item
          :active="!activeView && (activeEmployee && employee.EmployeeID === activeEmployee.EmployeeID || !activeEmployee && employee.EmployeeID === id)"
          @click.prevent="selectEmployee(employee)"
          :key="employee.EmployeeID"
          v-for="employee in $store.getters['employees/all']"
          :aria-describedby="_id('dropdown-header-label-teachers')"
        >
          {{ employee.Name }}
          <font-awesome-icon class="text-warning" icon="star" fixed-width
                             v-if="employee.EmployeeID === id"/>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-button v-if="isCustomerPortal === false & loggedIn" variant="secondary" class="mr-2" @click="showStudentSearchModal()">
        <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
        <span class="sr-only">{{ $t("context.search_student") }}</span>
      </b-button>
      <b-button v-if="isCustomerPortal === true & loggedIn & hasWwwUrl" variant="outline-primary" class="mr-2"
                @click="navigateMainSite()">
        <span>{{ $store.state.info.siteName }}</span>
      </b-button>

      <b-nav-item-dropdown right v-if="loggedIn && hasMultiplePersons">
        <template slot="button-content">
          <b-avatar variant="primary" :text="initials" size="2.5rem" class="mr-1 p-0" />
        </template>

        <b-dropdown-header :id="_id('dropdown-header-label-persons')" class="text-uppercase">
          Profielen
        </b-dropdown-header>
        <b-dropdown-item
          @click.prevent="$store.dispatch('persons/selectPerson', (person.PersonID || null))"
          :active="selectedPersonID && person.PersonID === selectedPersonID || selectedPersonID === null && !person.PersonID"
          :aria-describedby="_id('dropdown-header-label-persons')"
          :key="person.EmployeeID"
          v-for="person in persons"
        >
          {{ person.Name }}{{ person.Name2 ? ' ' + person.Name2 : '' }}
        </b-dropdown-item>

        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click.prevent="$store.dispatch('auth/logout')">
          <span>{{ $t("actions.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-button v-else-if="loggedIn" :to="{ name: 'login' }" variant="outline-primary"
                @click.prevent="$store.dispatch('auth/logout')">
        <span class="d-none d-md-inline">{{ $t("actions.logout") }}</span>
        <font-awesome-icon size="lg" :icon="['fad', 'right-from-bracket']" fixed-width class="ml-1"/>
      </b-button>
    </b-navbar-nav>

    <search-student-modal :id="_id('modal-search-person')" :ref="_id('modal-search-person')"/>
  </b-navbar>
</template>

<script>
// import Logo from 'https://api.dataduiker.nl/upload/golfbad-oss.svg'
// import Logo from '@/assets/logo.svg?inline'
import { isNull, has } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import SearchStudentModal from '@/components/SearchStudentModal'
import initialsMixin from '@/util/initialsMixin'

export default {
  name: 'Navbar',
  components: {
    // Logo
    SearchStudentModal
  },

  mixins: [initialsMixin],

  data () {
    return {
      intv: null
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isAdmin',
      'id'
    ]),
    ...mapGetters('groups', [
      'views',
      'activeView',
      'activeEmployee'
    ]),
    previewEnabled () {
      return this.$store.state.preview
    },
    logoUrl () {
      return require(`@/build/${this.$canonicalUser}/svg/logo.svg`)
    },
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    },
    hasWwwUrl () {
      return !!this.$store.state.info.wwwUrl
    },
    menu () {
      return this.$store.state.menu.menu
    },
    persons () {
      return [
        this.$store.getters['auth/user'],
        ...this.$store.getters['persons/all']
      ]
    },
    hasMultiplePersons () {
      return this.persons.length > 1
    },
    initials () {
      if (!this.hasMultiplePersons) return undefined

      const selectedPerson = (this.selectedPersonID === null ? this.persons[0] : this.persons.find(person => person.PersonID === this.selectedPersonID))
      return this.parseInitials(selectedPerson) || undefined
    }
  },

  methods: {
    ...mapActions('groups', [
      'setActiveEmployee',
      'setActiveView'
    ]),

    showStudentSearchModal () {
      this.$refs[this._id('modal-search-person')].show()
    },

    selectEmployee (employee, redirect = true) {
      this.setActiveEmployee(employee)
        .then(() => {
          if (redirect && this.$route.name !== 'home') {
            this.$router.push({ name: 'home' })
          }
        })
    },

    switchView (view, redirect = true) {
      this.setActiveView(view)
        .then(() => {
          if (redirect && this.$route.name !== 'home') {
            this.$router.push({ name: 'home' })
          }
        })
    },
    navigateMainSite () {
      window.location.href = `${this.$store.state.info.wwwUrl}/inloggen`
    }
  },

  mounted () {
    if (this.isAdmin) {
      if (!this.activeEmployee) {
        this.switchView(this.views[0], false)
      }
    }

    this.$store.dispatch('menu/fetch')
    this.$store.dispatch('banners/fetch')
    this.$store.dispatch('standardTexts/fetch')
  },

  watch: {
    isAdmin (isAdmin) {
      if (isAdmin) {
        if (!this.activeEmployee) {
          this.switchView(this.views[0], false)
        }
      }
    },

    loggedIn () {
      this.$store.dispatch('menu/fetch')
    },

    // This function redirects to the persons page when the selectedPersonID changes (user selection).
    selectedPersonID: {
      immediate: true,

      handler: function (value) {
        if (this.loggedIn) {
          const person = isNull(value) ? this.persons[0] : this.persons.find(person => person.PersonID === value)

          if (isNull(value) && this.$route.name !== 'home') {
            this.$router.push({ name: 'home' })
            return
          }

          if ((has(person, 'ActivityGroup.0.SkillCategory.DisplayType') && person.ActivityGroup[0].SkillCategory.DisplayType === 'CHILD_FRIENDLY') || this.previewEnabled) {
            return this.$router.push({ name: 'childhome', params: { student_id: person.PersonID } })
          }

          if (person) {
            this.$router.push({ name: 'person', params: { student_id: person.PersonID } })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .navbar-nav.no-collapse .dropdown-menu {
     position: absolute !important;
     float: left !important;
  }
</style>
