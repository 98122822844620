import router from '@/router'
import { publicApi, protectedApi } from '@/http'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'
export const SET_AUTH_TYPE = 'SET_AUTH_TYPE'

export const AUTH_TYPE_USER = 'Employee'
export const AUTH_TYPE_CUST = 'Customer'

const getJwtFromCookie = () => {
  const name = 'jwt='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

export const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const initialStatus = {
  loggedIn: false,
  loggingIn: false,
  failure: undefined
}
const initialState = {
  status: {
    ...initialStatus
  },
  user: {},
  credentials: {},
  authType: AUTH_TYPE_CUST
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  actions: {
    async login ({ dispatch, commit, state, rootGetters }, credentials = {}) {
      let { username, password, redirect, fetchUser } = credentials

      if (typeof redirect === 'undefined') {
        redirect = true
      }

      if (typeof fetchUser === 'undefined') {
        fetchUser = true
      }

      if (state.status.loggingIn) {
        // Retry
        return
      }

      commit(LOGIN_REQUEST, username)

      let grantRequest
      let grantType = 'password'

      if (!username && !password) {
        if (getJwtFromCookie()) {
          grantType = 'urn:ietf:params:oauth:grant-type:jwt-bearer'
          grantRequest = publicApi.post('/login', {
            grant_type: grantType,
            assertion: getJwtFromCookie()
          }, { withCredentials: true })
        } else {
          const err = new Error('No valid credentials found')
          commit(LOGIN_FAILURE, err)
          throw err
        }
      } else {
        grantRequest = publicApi.post('/login', {
          ...credentials
        }, { withCredentials: true })
      }

      try {
        const response = await grantRequest
        const tokens = response.data

        if (!fetchUser) {
          return commit(LOGIN_SUCCESS, tokens)
        }

        await commit(LOGIN_SUCCESS, tokens)
        await dispatch('fetchUser')

        if (grantType !== 'urn:ietf:params:oauth:grant-type:jwt-bearer' && redirect) {
          router.push({ name: 'home' })
        }
      } catch (err) {
        await dispatch('logout')
        await commit(LOGIN_FAILURE, err)
        throw err
      }
    },
    logout ({ commit, dispatch }) {
      publicApi.post('/logout')
      document.cookie = `jwt=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
      commit(LOGOUT)
      dispatch('groups/clear', {}, { root: true })
      dispatch('persons/clear', {}, { root: true })

      // Redirect to login page.
      if (router && router.currentRoute.name && router.currentRoute.name !== 'login') {
        router.push({ name: 'login' })
      }
    },
    fetchUser ({ commit, dispatch }) {
      return protectedApi.get('/me')
        .then(async response => {
          await commit(SET_USER, response.data)

          const getConfig = await dispatch('config/fetchConfig', {}, { root: true })
          const getEmployees = await dispatch('employees/fetch', undefined, { root: true })

          return Promise.all([getConfig, getEmployees]).then(() => {
            return getEmployees
          })
        })
    },
    setAuthType ({ commit }, authType) {
      commit(SET_AUTH_TYPE, authType)
    }
  },
  mutations: {
    [LOGIN_REQUEST] (state, username) {
      state.status.loggingIn = true
      state.status.failure = undefined
      if (username) {
        state.user.Name = username
      }
    },
    [LOGIN_SUCCESS] (state, credentials) {
      state.credentials = { ...credentials }
      state.status.loggedIn = true
      state.status.loggingIn = false
    },
    [LOGIN_FAILURE] (state, err) {
      let failure = 'Er is iets misgegaan bij het inloggen'

      if (err.response && err.response.data.error && err.response.data.error.message) {
        failure = err.response.data.error.message
      }

      state.status.loggingIn = false
      state.status.failure = failure
      state.user = { ...initialState.user }
    },
    [SET_USER] (state, user) {
      state.user = { ...user || initialState.user }
    },
    [LOGOUT] (state) {
      state.credentials = { ...initialState.credentials }
      state.status = { ...initialStatus }
      state.user = { ...initialState.user }
    },
    [SET_AUTH_TYPE] (state, type) {
      state.authType = type
    }
  },
  getters: {
    user: state => state.user,
    loggedIn: state => state.status.loggedIn,
    isAdmin: state => state.credentials.scope === 'full_access',
    userType: (state, getters) => getters.loggedIn && state.user.UserType ? state.user.UserType : undefined,
    username: state => [state.user.Name, state.user.Name2].filter(a => !!a).join(' ') || '',
    userId: state => state.user.UserID,
    id: state => state.user.EmployeeID || '',
    authType: state => state.authType
  }
}
