import { studentTracking } from '@/http'

import { has } from 'lodash'

export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_PERSONS = 'SET_PERSONS'
export const SET_LOADING = 'SET_LOADING'
export const SET_SEARCHRESULTS = 'SET_SEARCHRESULTS'
export const SET_SELECTED_PERSON = 'SET_SELECTED_PERSON'
export const SET_CELEBRATION_WATCHED = 'SET_CELEBRATION_WATCHED'

const sortPersons = (a, b) => {
  const startA = new Date(a.DateOfBirth)
  const startB = new Date(b.DateOfBirth)
  if (startA < startB) {
    return -1
  }
  if (startA > startB) {
    return 1
  }
  return 0
}

// What should I wipe on a logout?
const defaultState = () => ({
  persons: []
})

export default {
  namespaced: true,

  state: {
    loading: false,
    persons: [],
    searchedPersons: [],
    selectedPersonID: null,
    celebrationsWatched: []
  },

  actions: {
    fetch ({ commit, dispatch }) {
      commit(SET_LOADING, true)
      return studentTracking.get('persons').then(user => {
        dispatch('mergePersonSkills', user.data.items).then((persons) => {
          commit(SET_PERSONS, persons || [])
          commit(SET_LOADING, false)
        })
      }).catch(() => {
        commit(SET_PERSONS, [])
        commit(SET_LOADING, false)
      })
    },

    mergePersonSkills (_, persons) {
      persons.forEach(person => {
        person.ActivityGroup.forEach(group => {
          if (has(group, 'SkillCategory.Skills')) {
            group.SkillCategory.Skills.forEach((skill, index) => {
              const customerInsight = person.CustomerInsights.find(insight => insight.ActivityGroupID === group.ActivityGroupID)
              const customerInsightSkill = customerInsight.PersonSkill.find(personSkill => personSkill.SkillID === skill['Skill.SkillID'])

              if (customerInsight && customerInsightSkill) {
                group.SkillCategory.Skills[index] = {
                  ...skill,
                  ...customerInsightSkill
                }
              }
            })
          } else {
            // Skills not given in skillcategory, nothing to merge.
          }
        })
      })

      return persons
    },

    searchStudents ({ commit }, payload) {
      commit(SET_SEARCHRESULTS, [])
      commit(SET_LOADING, true)
      return studentTracking.get('persons', {
        params: {
          search: payload.searchString
        }
      }).then(response => {
        commit(SET_LOADING, false)
        commit(SET_SEARCHRESULTS, response.data.items || [])
      })
        .catch(error => {
          commit(SET_LOADING, false)
          console.log(error)
        })
    },

    selectPerson ({ commit }, id) {
      commit(SET_SELECTED_PERSON, id)
    },

    setCelebrationWatched ({ commit }, payload) {
      commit('SET_CELEBRATION_WATCHED', payload)
    },

    clear ({ commit }) {
      commit(CLEAR_STATE)
    }
  },

  mutations: {
    [CLEAR_STATE] (state) {
      Object.keys(defaultState()).forEach(key => {
        state[key] = defaultState()[key]
      })
      state.viewDate = new Date()
    },

    [SET_PERSONS] (state, persons) {
      state.persons = persons
    },
    [SET_LOADING] (state, loading) {
      state.loading = loading
    },
    [SET_SEARCHRESULTS] (state, persons) {
      state.searchedPersons = persons
    },
    [SET_SELECTED_PERSON] (state, id) {
      state.selectedPersonID = id
    },
    [SET_CELEBRATION_WATCHED] (state, payload) {
      state.celebrationsWatched.push(payload)
    }
  },

  getters: {
    all: state => state.persons,
    own: state => state.persons.sort(sortPersons),
    searchResults: state => state.searchedPersons,
    celebrationWatched: state => {
      return (celebrationID) => state.celebrationsWatched.includes(celebrationID)
    }
  }
}
