export default {
  computed: {
    student () {
      return this.$store.state.persons.persons.find(person => person.PersonID === this.$route.params.student_id)
    },

    skillCategory () {
      if (this.student) {
        const activityGroup = this.student.ActivityGroup.find((x) => { return x.ActivityGroupID === this.$route.params.group_id })
        if (activityGroup) {
          return {
            ActivityGroupID: activityGroup.ActivityGroupID,
            ...activityGroup.SkillCategory
          }
        }

        return false
      }
    },

    skills () {
      if (this.skillCategory) {
        return this.skillCategory.Skills
      }

      return false
    },

    skill () {
      if (this.skillCategory && this.$route.params.skill_id) {
        return this.skillCategory.Skills.find((x) => { return x.SkillID === this.$route.params.skill_id })
      }

      return {}
    },

    skillPassed () {
      if (this.skill) {
        return parseInt(this.skill.Rating) === parseInt(this.skill.MaxRating)
      }

      return false
    },

    skillsPassed () {
      if (this.skills) {
        return this.skills.filter(skill => parseInt(skill.Rating) === parseInt(skill.MaxRating)).length
      }

      return 0
    },

    allComplete () {
      if (this.skillCategory.Skills.length) {
        return this.skillCategory.Skills.every(skill => parseInt(skill.Rating) === skill.MaxRating)
      }

      return false
    }
  }
}
