<template>
  <div id="app" class="h-100">
    <navbar v-if="!$store.state.appLoading"></navbar>
    <transition name="grow" v-if="!$store.state.appLoading">
      <b-progress class="rounded-0" variant="secondary" :value="100" :height="'10px'" animated
                  v-if="$store.state.pageLoading"/>
    </transition>
    <transition name="fade" mode="out-in">
      <app-loader v-if="$store.state.appLoading"/>
      <keep-alive v-else :exclude="['Login']">
        <router-view :key="$route.fullPath"/>
      </keep-alive>
    </transition>
    <b-toast toaster="b-toaster-bottom-right" :visible="$store.state.sw" no-auto-hide title="Nieuwe versie beschikbaar"
             @hide="$store.dispatch('dismissRefreshNotification')">
      <b-button variant="primary" size="sm" @click="$store.dispatch('refreshToUpdate')">Verversen</b-button>
    </b-toast>
    <b-alert v-model="showOfflineAlert" variant="danger" :fade="true" dismissible class="fixed-bottom mb-0 text-center px-0 rounded-0">
      Geen netwerkverbinding.
    </b-alert>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import AppLoader from '@/components/AppLoader'

export default {
  components: { AppLoader, Navbar },
  data () {
    return {
      showDismissibleAlert: false
    }
  },
  mounted () {
    if (new URLSearchParams(window.location.search).has('preview')) {
      this.$store.dispatch('setPreviewMode', true)
    }
  },
  computed: {
    showOfflineAlert () {
      return this.$store.state.appOffline
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.grow-enter-active,
.grow-leave-active {
  transition-duration: 0.2s;
  transition-property: height;
  transition-timing-function: ease;
}

.grow-enter,
.grow-leave-active {
  height: 0 !important;
}
</style>
